// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/F2515893/Desktop/OptimumOnline/web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/F2515893/Desktop/OptimumOnline/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-js": () => import("/Users/F2515893/Desktop/OptimumOnline/web/src/pages/new.js" /* webpackChunkName: "component---src-pages-new-js" */),
  "component---src-pages-run-js": () => import("/Users/F2515893/Desktop/OptimumOnline/web/src/pages/run.js" /* webpackChunkName: "component---src-pages-run-js" */),
  "component---src-pages-show-js": () => import("/Users/F2515893/Desktop/OptimumOnline/web/src/pages/show.js" /* webpackChunkName: "component---src-pages-show-js" */),
  "component---src-pages-user-js": () => import("/Users/F2515893/Desktop/OptimumOnline/web/src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */)
}

exports.data = () => import("/Users/F2515893/Desktop/OptimumOnline/web/.cache/data.json")

