/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require("./src/styles/global.css");

/* This is required for react-pose to work with Gatsby as of 2018-10-15 */
var createElement = require('react').createElement;
exports.replaceComponentRenderer = ({ props }) => {
    return createElement(props.pageResources.component, {...props});
}
